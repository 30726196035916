import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import OrganizationProfileEditInfoPopup from "@/presentation/components/modals/organization-profile-edit-info/OrganizationProfileEditInfo.vue";
import ProfilePreload from "@/presentation/components/preloader/profile-preload/ProfilePreload.vue";
import DIContainer from "@/core/DIContainer";
import Organization from "@/domain/entity/Organization";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import StringUtils from "@/commons/StringUtils";
import transform from "@/commons/TransformUtil";
export default defineComponent({
    name: "OrganizationProfileInfo",
    components: {
        OrganizationProfileEditInfoPopup: OrganizationProfileEditInfoPopup,
        ProfilePreload: ProfilePreload
    },
    props: ["organizationInfo", "breadcrumb", "preload"],
    setup: function (props) {
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var router = useRouter();
        var state = reactive({
            organizationInfo: new Organization(),
            showEditPopup: false,
            editField: "",
            listPerfecture: [],
        });
        var openEditModal = function (field) {
            state.showEditPopup = true;
            state.editField = field;
        };
        function updateField(data) {
            if (!data) {
                return "";
            }
            if (!data.field) {
                return "";
            }
            ;
            props.organizationInfo[data.field] = data.value;
        }
        onMounted(function () {
            props.breadcrumb[2] = { name: "基本情報", route: "" };
        });
        return {
            state: state,
            openEditModal: openEditModal,
            props: props,
            updateField: updateField,
            transform: transform,
            StringUtils: StringUtils,
        };
    }
});
