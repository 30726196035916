<template>
  <div>
    <ProfilePreload v-if="!props.organizationInfo.name"/>
    <div v-else class="basic_info">
      <div class="basic_info__row">
        <label>企業名</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.name }"
        >{{ props.organizationInfo.name || "情報がありません" }}</div>
      </div>
      <div class="basic_info__row">
        <label>（企業名）カナ</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.name_kana }"
        >{{ props.organizationInfo.name_kana || "情報がありません" }}</div>
      </div>
      <div class="basic_info__row">
        <label >業種</label>
        <div v-if="props.organizationInfo.industries.length || props.organizationInfo.industry_optional" class="content">
              <span v-for="(industy, i) in props.organizationInfo.industries" :key="i">
                  {{ industy.name }}
              </span>
              <span v-if="props.organizationInfo.industry_optional" v-html="StringUtils.trimLine(props.organizationInfo.industry_optional, 100)"></span>
        </div>
        <div v-if="!props.organizationInfo.industries.length && !props.organizationInfo.industry_optional" class="content no-data">
            情報がありません
        </div>
        <a href="javascript:void(0);" class="icon-edit" @click="openEditModal('industries')">
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>所在地</label>
        <div class="content">
          <span
            v-if="props.organizationInfo.prefecture_name || props.organizationInfo.city_name"
          >{{props.organizationInfo.prefecture_name}}</span>
          &nbsp;&nbsp;
          <span
            v-if="props.organizationInfo.prefecture_name || props.organizationInfo.city_name"
          >{{props.organizationInfo.city_name}}</span>
          <span
            class="content"
            v-else
            v-bind:class="{ 'no-data': !props.organizationInfo.prefecture_name && !props.organizationInfo.city_name }"
          >情報がありません</span>
        </div>
        <a href="javascript:void(0);" class="icon-edit" @click="openEditModal('perfecture')">
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>
          担当者氏名
          <br />
          <span class="note">※公開されません</span>
        </label>
        <div class="content" v-if="props.organizationInfo.family_name || props.organizationInfo.given_name">
          <span>{{ props.organizationInfo.family_name + ' ' + props.organizationInfo.given_name }}</span>
        </div>
        <div v-else class="content no-data">
          情報がありません
        </div>
        <a href="javascript:void(0);" class="icon-edit" @click="openEditModal('name')">
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>
          （担当者氏名）カナ
          <br />
          <span class="note">※公開されません</span>
        </label>
        <div class="content" v-if="props.organizationInfo.family_name_kana || props.organizationInfo.given_name_kana">
          <span>{{ props.organizationInfo.family_name_kana + ' ' + props.organizationInfo.given_name_kana }}</span>
        </div>
        <div v-else class="content no-data">
          情報がありません
        </div>
        <a href="javascript:void(0);" class="icon-edit" @click="openEditModal('name_kana')">
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>
          連絡先電話番号
          <br />
          <span class="note">※公開されません</span>
        </label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.phone_number }"
        >{{ transform.formatPhoneNumber(props.organizationInfo.phone_number) || "情報がありません" }}</div>
        <a href="javascript:void(0);" class="icon-edit" @click="openEditModal('phone_number')">
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>
          メールアドレス
          <br />
          <span class="note">※公開されません</span>
        </label>
        <div class="content" v-bind:class="{ 'no-data': !props.organizationInfo.email }">
          {{ props.organizationInfo.email || '情報がありません' }}
        </div>
        <a
          href="javascript:void(0);"
          class="icon-edit"
          @click="openEditModal('email')"
        >
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>
          2チームとのマッチングを<br />許可しますか？
          <br />
          <span class="note">※公開されません</span>
        </label>
        <div class="content" v-bind:="{ 'no-data': !props.organizationInfo.is_multiple_matching }">
          {{ props.organizationInfo.is_multiple_matching ? '許可する' : '許可しない' }}
        </div>
      </div>
      <br />
      <div class="basic_info__row">
        <label>事業概要</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.profile_description }"
        >{{ props.organizationInfo.profile_description || "情報がありません" }}</div>
        <a
          href="javascript:void(0);"
          class="icon-edit"
          @click="openEditModal('profile_description')"
        >
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <div class="basic_info__row">
        <label>DXへの取り組み状況</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.profile_ai_description }"
        >{{ props.organizationInfo.profile_ai_description || "情報がありません" }}</div>
        <a
          href="javascript:void(0);"
          class="icon-edit"
          @click="openEditModal('profile_ai_description')"
        >
          <i class="fal fa-edit" aria-hidden="true"></i>
        </a>
      </div>
      <OrganizationProfileEditInfoPopup
        v-if="state.showEditPopup"
        :field="state.editField"
        :info="props.organizationInfo"
        :perfectures="state.listPerfecture"
        @updatevalue="updateField"
        @close="state.showEditPopup = false;"
      />
    </div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/organization/my-profile/info/Info.ts" />
<style lang="scss" src="@/presentation/views/organization/my-profile/info/Info.scss" scoped></style>
