var OrganizationUpdate = /** @class */ (function () {
    function OrganizationUpdate() {
        this.city_id = null;
        this.prefecture_id = null;
        this.phone_number = null;
        this.profile_ai_description = "";
        this.profile_description = "";
        this.image = null;
        this.industries = [];
        this.industry_optional = "";
        this.family_name = "";
        this.given_name = "";
        this.family_name_kana = "";
        this.given_name_kana = "";
        this.edit_field = "";
    }
    return OrganizationUpdate;
}());
export default OrganizationUpdate;
