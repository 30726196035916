<template>
    <div class="profile-preload">
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
        <div class="row-preload">
            <div class="text_preload">

            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/profile-preload/ProfilePreload.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/profile-preload/ProfilePreload.scss" scoped>
</style>
