import LanguageUtil from "@/commons/LanguageUtil";
import { cloneDeep } from "lodash";
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import OrganizationUpdate from '@/domain/entity/OrganizationUpdate';
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "OrganizationProfileEditInfo",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "field",
        "info",
        "perfectures"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var _b = useForm({
            initialValues: cloneDeep(props.info)
        }), handleSubmit = _b.handleSubmit, values = _b.values, setValues = _b.setValues, errors = _b.errors, isSubmitting = _b.isSubmitting;
        setValues({ family_name: props.info.family_name });
        setValues({ given_name: props.info.given_name });
        setValues({ family_name_kana: props.info.family_name_kana });
        setValues({ given_name_kana: props.info.given_name_kana });
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            field: props.field,
            errorUpdateOrganizationProfileInfoMessage: null,
            errorValidateMessage: {},
            listPerfecture: [],
            listCities: [],
            cityName: "",
            prefectureName: "",
            listIndustry: [],
        });
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var industries = useField("industries", yup
            .array()
            .nullable()).value;
        var industry_optional = useField("industry_optional", yup
            .string()
            .nullable()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var family_name = useField("family_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var given_name = useField("given_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var family_name_kana = useField("family_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var given_name_kana = useField("given_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var phone_number = useField("phone_number", yup
            .string()
            .required(state.aLang.validation_required)
            .matches(Constant.PHONE_PATTERN, { message: state.aLang.validation_format_phone_number, excludeEmptyString: true })
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var email = useField("email", yup
            .string()
            .required(state.aLang.validation_required)
            .email(state.aLang.validation_format_email)
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var profile_ai_description = useField("profile_ai_description", yup
            .string()
            .nullable()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var prefecture_id = useField("prefecture_id", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var city_id = useField("city_id", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var profile_description = useField("profile_description", yup
            .string()
            .nullable()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var onSubmit = handleSubmit(function () {
            var params = new OrganizationUpdate();
            for (var _i = 0, _a = Object.entries(values); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (value) {
                    params[key] = value;
                }
            }
            delete params["image"];
            params['edit_field'] = state.field;
            return organizationInteractor
                .updateMyProfile(params)
                .then(function (result) {
                switch (props.field) {
                    case 'perfecture':
                        emit('updatevalue', {
                            field: "city_name",
                            value: state.cityName
                        });
                        emit('updatevalue', {
                            field: "prefecture_name",
                            value: state.prefectureName
                        });
                        emit('updatevalue', {
                            field: "city_id",
                            value: values.city_id
                        });
                        emit('updatevalue', {
                            field: "prefecture_id",
                            value: values.prefecture_id
                        });
                        break;
                    case 'industries':
                        emit('updatevalue', {
                            field: "industries",
                            value: values.industries
                        });
                        emit('updatevalue', {
                            field: "industry_optional",
                            value: values.industry_optional
                        });
                        break;
                    case 'name':
                        emit('updatevalue', {
                            field: "family_name",
                            value: values.family_name
                        });
                        emit('updatevalue', {
                            field: "given_name",
                            value: values.given_name
                        });
                        break;
                    case 'name_kana':
                        emit('updatevalue', {
                            field: "family_name_kana",
                            value: values.family_name_kana
                        });
                        emit('updatevalue', {
                            field: "given_name_kana",
                            value: values.given_name_kana
                        });
                        break;
                    default:
                        emit('updatevalue', {
                            field: props.field,
                            value: values[props.field]
                        });
                        break;
                }
                emit('close');
            }, function (error) {
                if (error.validation_errors) {
                    state.errorValidateMessage = error.validation_errors;
                }
                state.errorUpdateOrganizationProfileInfoMessage = error.message;
            });
        });
        function checkExistCities(city_id) {
            var cities = state.listCities.findIndex(function (o) { return o.id === city_id; });
            return cities != -1 ? true : false;
        }
        function getCityName(id) {
            var city = state.listCities.filter(function (o) { return o.id == id; })[0];
            return city ? city.name : "";
        }
        function getCitiesByPerfecture(prefecture_id) {
            if (!prefecture_id) {
                return "";
            }
            return masterInteractor
                .getAllCitiesByPerfecture(prefecture_id)
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listCities = result.data;
                if (store.state.organizationProfileInfo
                    .city_id) {
                    setValues({
                        city_id: store.state.organizationProfileInfo
                            .city_id
                    });
                }
                if (values.city_id) {
                    if (checkExistCities(values.city_id)) {
                        var cityName = getCityName(values.city_id);
                        state.cityName = cityName;
                    }
                    else {
                        setValues({
                            city_id: ""
                        });
                    }
                }
                else {
                    setValues({
                        city_id: ""
                    });
                }
            });
        }
        function onChangeCity(cityId) {
            if (!cityId) {
                return "";
            }
            var cityName = getCityName(cityId);
            setValues({
                city_id: cityId
            });
            state.cityName = cityName;
        }
        function onChangePrefecture(prefectureId) {
            state.listCities = [];
            if (!prefectureId) {
                return "";
            }
            var prefectureName = getPerfectureName(prefectureId);
            setValues({
                city_id: ""
            });
            state.prefectureName = prefectureName;
            getCitiesByPerfecture(prefectureId);
        }
        function getAllPrefecture() {
            return masterInteractor.getAllPerfecture().then(function (result) {
                if (!result.data) {
                    return "";
                }
                state.listPerfecture = result.data;
                if (values.prefecture_id) {
                    getCitiesByPerfecture(values.prefecture_id);
                    var prefectureName = getPerfectureName(values.prefecture_id);
                    setValues({
                        prefecture_id: values.prefecture_id
                    });
                    state.prefectureName = prefectureName;
                }
            });
        }
        function getPerfectureName(id) {
            var perfecture = state.listPerfecture.filter(function (o) { return o.id == id; })[0];
            return perfecture ? perfecture.name : "";
        }
        function getIndustries() {
            return masterInteractor.getIndustries().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listIndustry = result.data;
            });
        }
        function selectedIndustry(industry) {
            return store.state.organizationProfileInfo.industries.filter(function (selected) {
                return selected.id === industry.id;
            }).length > 0;
        }
        function checkIndustry(event, industry) {
            var industries = values.industries;
            if (event.target.checked) {
                industries.push(industry);
            }
            else {
                industries = industries.filter(function (checked) {
                    return checked.id != industry.id;
                });
            }
            setValues({ industries: industries });
        }
        onMounted(function () {
            if (props.field === "industries") {
                getIndustries();
            }
            if (props.field === "perfecture") {
                getAllPrefecture();
            }
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit,
            onChangePrefecture: onChangePrefecture,
            onChangeCity: onChangeCity,
            isSubmitting: isSubmitting,
            selectedIndustry: selectedIndustry,
            checkIndustry: checkIndustry,
        };
    }
});
