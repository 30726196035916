var OrganizationRegister = /** @class */ (function () {
    function OrganizationRegister() {
        this.name = "";
        this.name_kana = "";
        this.project_place_city_id = "";
        this.project_place_city_name = "";
        this.project_place_prefecture_id = "";
        this.project_place_prefecture_name = "";
        this.corporate_number = "";
        this.phone_number = "";
        this.email = "";
        this.password = "";
        this.password_confirmation = "";
        this.family_name = "";
        this.given_name = "";
        this.family_name_kana = "";
        this.given_name_kana = "";
        this.is_multiple_matching = false;
    }
    return OrganizationRegister;
}());
export default OrganizationRegister;
