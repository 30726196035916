<template>
    <div class="input__component">
        <div class="input__counter" v-if="state.maxlength">
            {{ state.count }} / {{ state.maxlength }}
        </div>
        <input
            v-bind:placeholder="state.placeholder"
            v-model="value"
            @input="countCharacters"
            :maxlength="state.maxlength"
            :type="state.type"
            :id="state.id"
            :name="state.name"
            :autocomplete="state.autocomplete"
            :required="state.required"
            @focus="$emit('focus')"
        />
    </div>
</template>

<script lang="ts" src="@/presentation/components/input/Input.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/input/Input.scss" scoped>
</style>
