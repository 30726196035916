<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="profile_info__edit__container">
                <div class="profile_info__edit__container-title">編集中</div>
                <form class="horizontal" @submit="onSubmit">
                  <div class="form-ctrl" v-if="state.field == 'industries'">
                    <label class="text">業種</label>
                    <div class="form-checkbox-group">
                      <div class="checkbox" v-for="(industry, i) in state.listIndustry" :key="i">
                        <input
                            :id="`industry_${industry.id}`"
                            name="industries"
                            type="checkbox"
                            :value="industry.id"
                            :checked="selectedIndustry(industry)"
                            @change="checkIndustry($event, industry)"
                        />
                        <label :for="`industry_${industry.id}`">{{ industry.name }}</label>
                      </div>
                      <ErrorMessage
                          v-if="errors.industries"
                          :message="errors.industries"
                      />
                      <template
                        v-if="state.errorValidateMessage.industries"
                      >
                        <ErrorMessage
                          v-for="m of state.errorValidateMessage.industries"
                          :key="m"
                          :message="m"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                    <div class="form-checkbox-optional">
                      <label class="text">その他</label>
                      <div class="form-group">
                        <Textarea
                            name="industry_optional"
                            maxlength="10000"
                            placeholder="選択肢にない業種や、より詳細な業種の情報があればご記入ください。"
                            v-model="values.industry_optional"
                        />
                        <ErrorMessage
                            v-if="errors.industry_optional"
                            :message="errors.industry_optional"
                        />
                        <template
                          v-if="state.errorValidateMessage.industry_optional"
                        >
                          <ErrorMessage
                            v-for="m of state.errorValidateMessage.industry_optional"
                            :key="m"
                            :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="form-ctrl" v-if="state.field == 'perfecture'">
                    <label for class="text">所在地</label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <select
                          v-if="state.field == 'perfecture'"
                          v-model="values.prefecture_id"
                          :class="{ invalid: !values.prefecture_id }"
                          @change="onChangePrefecture(values.prefecture_id)"
                          class="select"
                        >
                          <option value selected>都道府県</option>
                          <template v-if="state.listPerfecture.length">
                            <option
                              v-for="perfecture in state.listPerfecture"
                              :key="perfecture"
                              :value="perfecture.id"
                            >{{ perfecture.name }}</option>
                          </template>
                        </select>
                        <ErrorMessage
                          v-if="errors.prefecture_id"
                          :message="errors.prefecture_id"
                        ></ErrorMessage>
                        <template
                          v-if="state.errorValidateMessage.prefecture_id"
                        >
                          <ErrorMessage
                            v-for="m of state.errorValidateMessage.prefecture_id"
                            :key="m"
                            :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                      <div class="form-group">
                        <select
                          v-if="state.field == 'perfecture'"
                          :class="{ invalid: !values.city_id }"
                          class="select"
                          @change="onChangeCity(values.city_id)"
                          v-model="values.city_id"
                        >
                          <option value selected>市区町村</option>
                          <template v-if="state.listCities.length">
                            <option
                              v-for="cities in state.listCities"
                              :key="cities"
                              :value="cities.id"
                            >{{ cities.name }}</option>
                          </template>
                        </select>
                        <ErrorMessage
                          v-if="errors.city_id"
                          :message="errors.city_id"
                        ></ErrorMessage>
                        <template
                          v-if="state.errorValidateMessage.city_id"
                        >
                          <ErrorMessage
                            v-for="m of state.errorValidateMessage.city_id"
                            :key="m"
                            :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="form-ctrl" v-if="state.field == 'name'">
                    <label class="text form-name-label">
                      担当者氏名
                      <br />
                      <span class="note">※公開されません</span>
                    </label>
                    <div class="form-name-group">
                      <div class="form-el">
                        <Input
                          name="family_name"
                          maxlength="64"
                          type="text"
                          placeholder="苗字を入力してください"
                          v-model="values.family_name"
                        />
                        <ErrorMessage
                          v-if="errors.family_name"
                          :message="errors.family_name"
                        />
                        <template
                          v-if="state.errorValidateMessage.family_name"
                        >
                          <ErrorMessage
                              v-for="m of state.errorValidateMessage.family_name"
                              :key="m"
                              :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                      <div class="form-el">
                        <Input
                          name="given_name"
                          maxlength="64"
                          type="text"
                          placeholder="名前を入力してください"
                          v-model="values.given_name"
                        />
                        <ErrorMessage
                          v-if="errors.given_name"
                          :message="errors.given_name"
                        />
                        <template
                          v-if="state.errorValidateMessage.given_name"
                        >
                          <ErrorMessage
                            v-for="m of state.errorValidateMessage.given_name"
                            :key="m"
                            :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="form-ctrl" v-if="state.field == 'name_kana'">
                    <label class="text form-name-label">
                      （担当者氏名）カナ
                      <br />
                      <span class="note">※公開されません</span>
                    </label>
                    <div class="form-name-group">
                      <div class="form-el">
                        <Input
                            name="family_name_kana"
                            maxlength="64"
                            type="text"
                            placeholder="苗字カナを入力してください"
                            v-model="values.family_name_kana"
                        />
                        <ErrorMessage
                            v-if="errors.family_name_kana"
                            :message="errors.family_name_kana"
                        />
                        <template
                          v-if="state.errorValidateMessage.family_name_kana"
                        >
                          <ErrorMessage
                            v-for="m of state.errorValidateMessage.family_name_kana"
                            :key="m"
                            :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                      <div class="form-el">
                        <Input
                            name="given_name_kana"
                            maxlength="64"
                            type="text"
                            placeholder="名前カナを入力してください"
                            v-model="values.given_name_kana"
                        />
                        <ErrorMessage
                            v-if="errors.given_name_kana"
                            :message="errors.given_name_kana"
                        />
                        <template
                          v-if="state.errorValidateMessage.given_name_kana"
                        >
                          <ErrorMessage
                            v-for="m of state.errorValidateMessage.given_name_kana"
                            :key="m"
                            :message="m"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- phone number -->
                  <div class="form-ctrl" v-if="state.field == 'phone_number'">
                    <label for="phone_number" class="text">
                      連絡先電話番号
                      <br />
                      <span class="note">※公開されません</span>
                    </label>
                    <div class="form-group">
                      <Input
                        maxlength="11"
                        id="phone_number"
                        type="tel"
                        name="phone_number"
                        v-model="values.phone_number"
                        placeholder="ハイフン無しでご入力ください"
                      />
                      <ErrorMessage v-if="errors.phone_number" :message="errors.phone_number" />
                      <template
                        v-if="state.errorValidateMessage.phone_number"
                      >
                        <ErrorMessage
                          v-for="m of state.errorValidateMessage.phone_number"
                          :key="m"
                          :message="m"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                  <!-- profile_description -->
                  <div class="form-ctrl" v-if="state.field == 'profile_description'">
                    <label for="profile_description" class="text">事業概要</label>
                    <div class="form-group">
                      <Textarea
                        id="profile_description"
                        type="text"
                        name="profile_description"
                        v-model="values.profile_description"
                        placeholder="入力してください"
                        maxlength="10000"
                      />
                      <ErrorMessage
                        v-if="errors.profile_description"
                        :message="errors.profile_description"
                      />
                      <template
                        v-if="state.errorValidateMessage.profile_description"
                      >
                        <ErrorMessage
                          v-for="m of state.errorValidateMessage.profile_description"
                          :key="m"
                          :message="m"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                  <!-- email -->
                  <div class="form-ctrl" v-if="state.field == 'email'">
                    <label class="text">
                      メールアドレス
                      <br />
                      <span class="note">※公開されません</span>
                    </label>
                    <div class="form-group">
                      <Input
                        maxlength="255"
                        id="email"
                        type="email"
                        name="email"
                        v-model="values.email"
                        placeholder="ご入力ください"
                      />
                      <ErrorMessage v-if="errors.email" :message="errors.email" />
                      <template
                        v-if="state.errorValidateMessage.email"
                      >
                        <ErrorMessage
                          v-for="m of state.errorValidateMessage.email"
                          :key="m"
                          :message="m"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                  <!-- profile_ai_description -->
                  <div class="form-ctrl" v-if="state.field == 'profile_ai_description'">
                    <label for="profile_ai_description" class="text">DXへの取り組み状況</label>
                    <div class="form-group">
                      <Textarea
                        id="profile_ai_description"
                        type="text"
                        name="profile_ai_description"
                        v-model="values.profile_ai_description"
                        placeholder="入力してください"
                        maxlength="10000"
                      />
                      <ErrorMessage
                        v-if="errors.profile_ai_description"
                        :message="errors.profile_ai_description"
                      />
                      <template
                        v-if="state.errorValidateMessage.profile_ai_description"
                      >
                        <ErrorMessage
                          v-for="m of state.errorValidateMessage.profile_ai_description"
                          :key="m"
                          :message="m"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                  <div class="form-ctrl"  v-if="state.errorUpdateOrganizationProfileInfoMessage">
                    <label class="text"></label>
                    <div class="form-group">
                       <ErrorMessage
                        :message="state.errorUpdateOrganizationProfileInfoMessage"
                        />
                    </div>
                  </div>
                  <div class="profile_info__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >キャンセル</button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                    >
                      <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                      編集完了
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/organization-profile-edit-info/OrganizationProfileEditInfo.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-profile-edit-info/OrganizationProfileEditInfo.scss" />
