import { defineComponent, reactive, computed, watch } from "vue";
export default defineComponent({
    name: "Textarea",
    props: ["placeholder", "maxlength", "modelValue", "rows", "disabled"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            placeholder: props.placeholder,
            maxlength: props.maxlength,
            count: props.modelValue ? props.modelValue.length : 0
        });
        function countCharacters(e) {
            emit("update:modelValue", e.target.value);
            state.count = e.target.value.length;
        }
        ;
        var value = computed({
            get: function () {
                return props.modelValue;
            },
            set: function (val) {
                emit("update:modelValue", val);
                state.count = val.length;
            }
        });
        watch(props, function (newVal, oldVal) {
            if (newVal.modelValue != null && newVal.modelValue != undefined) {
                state.count = newVal.modelValue.length;
            }
        }, { immediate: true });
        return {
            state: state,
            props: props,
            value: value,
            countCharacters: countCharacters
        };
    }
});
