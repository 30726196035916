export default {
    PASSWORD_PATTERN: /^[0-9a-zA-Z!#$%&()*+,-.\/:;<=>?@[\]^_`{|}~\'\"]{8,20}$/,
    PHONE_PATTERN: /^[0-9]{10,11}$/,
    KANA_PATTERN: /^([\u30a0-\u30ff]*)$/,
    URL_PATTERN: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    GITHUB_URL_PATTERN: /^(https?:\/\/)?(www\.)?github.com\/[a-zA-Z0-9(\.\?)?]/,
    QIITA_URL_PATTERN: /^(https?:\/\/)?(www\.)?qiita.com\/[a-zA-Z0-9(\.\?)?]/,
    YOUTUBE_URL_PATTERN: /^(https?:\/\/)?((www\.)?youtube\.com\/|youtu\.be\/)[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
    LIMIT_SMALL: 10,
    LIMIT_BIG: 50,
    GUIDE_OR_DASHBOARD: "GUIDE_OR_DASHBOARD",
    GUIDE_USER_DASHBOARD: "GUIDE_USER_DASHBOARD",
    GUIDE_USER_PROFILE: "GUIDE_USER_PROFILE",
    GUIDE_USER_SKILL: "GUIDE_USER_SKILL",
    GUIDE_USER_ACTI: "GUIDE_USER_ACTI",
    GUIDE_USER_RATTING: "GUIDE_USER_RATTING",
    GUIDE_USER_TEAM: "GUIDE_USER_TEAM",
};
