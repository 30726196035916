var StringUtils = /** @class */ (function () {
    function StringUtils() {
    }
    StringUtils.urlifyString = function (text) {
        if (!text) {
            return;
        }
        // http://, https://, ftp://
        var urlPattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        // www. sans http:// or https://
        var pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        // Email addresses
        var emailAddressPattern = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        return text
            .replace(urlPattern, '<a href="$1" target="_blank">$1</a>')
            .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
            .replace(emailAddressPattern, '<a href="mailto:$1">$1</a>');
    };
    StringUtils.extractYoutubeId = function (url) {
        var result = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return result ? result[1] : null;
    };
    StringUtils.trimLine = function (text, charLength, clamp) {
        if (clamp === void 0) { clamp = "..."; }
        return text.slice(0, charLength) + (charLength < text.length ? clamp : '');
    };
    return StringUtils;
}());
export default StringUtils;
