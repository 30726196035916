<template>
    <div class="textarea__component">
        <div class="textarea__counter" v-if="state.maxlength">
            {{ state.count }} / {{ state.maxlength }}
        </div>
        <textarea
            :placeholder="state.placeholder"
            v-model="value"
            @input="countCharacters"
            :maxlength="state.maxlength"
            :rows="props.rows"
            :disabled="props.disabled"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @keypress="$emit('keypress')"
        ></textarea>
    </div>
</template>

<script lang="ts" src="@/presentation/components/textarea/Textarea.ts" scoped>
</script>

<style lang="scss" src="@/presentation/components/textarea/Textarea.scss" scoped>
</style>
